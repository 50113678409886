import axios from 'axios';

const UIForm = document.getElementById('html-form');
const UIAlert = document.getElementById('alert');
const UIAlertMsg = UIAlert.querySelector('p');
UIForm.addEventListener('submit', sendMail);

function sendMail (event) {
  const data = new FormData(UIForm);
  UIAlert.classList.add('hidden');
  UIAlertMsg.textContent = '';

  axios.post('PHPMailer/_mailformular.php', data)
    .then(res => {
      UIAlert.classList.remove('hidden');
      UIAlertMsg.textContent = res.data;
      UIForm.classList.add('hidden');
    })
    .catch((err) => {
      console.log(err);
      UIAlert.classList.remove('hidden');
      UIAlertMsg.textContent = 'Ein Fehler ist aufgetreten.';
    });

  event.preventDefault();
}

function scrollWindow (el, position) {
  const UIMac = document.querySelector(el);
  UIMac.scrollTo({
    behavior: 'smooth',
    top: position
  });
}

function scrollAnimation () {
  setTimeout(() => {
    scrollWindow('.window', document.getElementById('shopScreen').clientHeight / 1.3);
  }, 1500);
  setTimeout(() => {
    scrollWindow('.window', 0);
  }, 2700);
}

window.addEventListener('load', scrollAnimation());
